// class FormHandler {
// 	constructor(formId, modalId, successMessage, action) {
// 		this.form = document.getElementById(formId);
// 		this.modal = document.getElementById(modalId);
// 		this.loader = document.getElementById('loader');
// 		this.successModal = document.getElementById('successModal');
// 		this.successModalTitle = document.getElementById('successModalLabel');
// 		this.successModalBody = this.successModal.querySelector('.modal-body p');
// 		this.successMessage = successMessage;
// 		this.action = action;

// 		this.attachSubmitListener();
// 		// this.attachModalHiddenListener();
// 	}

// 	attachSubmitListener() {
// 		this.form.addEventListener('submit', (event) => this.handleSubmit(event));
// 	}

// 	async handleSubmit(event) {
// 		event.preventDefault();
// 		const formData = new FormData(this.form);

// 		this.showLoader();
// 		this.closeModal();

// 		try {
// 			const response = await fetch(
// 				`/wp-admin/admin-ajax.php?action=${this.action}`,
// 				{
// 					method: 'POST',
// 					body: formData,
// 				}
// 			);
// 			const result = await response.json();
// 			this.hideLoader();

// 			if (result.success) {
// 				this.showSuccessModal(this.successMessage);
// 			} else {
// 				this.showSuccessModal(
// 					result.data.message || 'Please try again later.',
// 					false
// 				);
// 			}
// 		} catch (error) {
// 			this.showSuccessModal('Failed to submit. Please try again later.', false);
// 		}
// 	}

// 	attachModalHiddenListener() {
// 		this.modal.addEventListener('hidden.bs.modal', () => {
// 			this.form.reset();
// 			const conditionalFields = [
// 				'convictionDetails',
// 				'healthDetails',
// 				'adjustmentDetails',
// 			];
// 			conditionalFields.forEach((fieldId) => {
// 				const fieldElement = document.getElementById(fieldId);
// 				if (fieldElement) {
// 					fieldElement.style.display = 'none';
// 				}
// 			});
// 		});
// 	}

// 	showLoader() {
// 		if (this.loader) {
// 			this.loader.style.display = 'flex';
// 		}
// 	}

// 	hideLoader() {
// 		if (this.loader) {
// 			this.loader.style.display = 'none';
// 		}
// 	}

// 	closeModal() {
// 		const modalInstance = bootstrap.Modal.getInstance(this.modal);
// 		modalInstance.hide();
// 	}

// 	showSuccessModal(message, isSuccess = true) {
// 		this.successModalTitle.textContent = isSuccess ? 'Success' : 'Error';
// 		this.successModalBody.textContent = message;
// 		const successModalInstance = new bootstrap.Modal(this.successModal);
// 		successModalInstance.show();
// 	}
// }

// export default FormHandler;

class FormHandler {
	constructor(formId, modalId, successMessage, action) {
		this.form = document.getElementById(formId);
		this.modal = document.getElementById(modalId);
		this.loader = document.getElementById('loader');
		this.successModal = document.getElementById('successModal');
		this.successModalTitle = document.getElementById('successModalLabel');
		this.successModalBody = this.successModal.querySelector('.modal-body p');
		this.successMessage = successMessage;
		this.action = action;
		this.modalId = modalId;

		this.attachSubmitListener();
	}

	attachSubmitListener() {
		this.form.addEventListener('submit', (event) => this.handleSubmit(event));
	}

	async handleSubmit(event) {
		event.preventDefault();

		// Clear previous error styles
		this.clearErrorStyles();

		// Validate form before submitting
		const validationError = this.validateForm();
		if (validationError) {
			this.showSuccessModal(validationError, false);
			return; // Do not close modal if validation fails
		}

		const formData = new FormData(this.form);

		this.showLoader();
		this.closeModal();

		try {
			const response = await fetch(
				`/wp-admin/admin-ajax.php?action=${this.action}`,
				{
					method: 'POST',
					body: formData,
				}
			);
			const result = await response.json();
			this.hideLoader();

			if (result.success) {
				this.showSuccessModal(this.successMessage);
			} else {
				this.showSuccessModal(
					result.data.message || 'Please try again later.',
					false
				);
			}
		} catch (error) {
			this.showSuccessModal('Failed to submit. Please try again later.', false);
		}
	}

	validateForm() {
		// Dynamic selectors using modalId
		const criminalConviction = this.form.querySelector(
			`[name="criminalConviction_${this.modalId}"]:checked`
		);
		const convictionDetails = this.form.querySelector(
			`#convictionInfo_${this.modalId}`
		);
		const healthIssues = this.form.querySelector(
			`[name="healthIssues_${this.modalId}"]:checked`
		);
		const healthInfo = this.form.querySelector(`#healthInfo_${this.modalId}`);
		const adjustmentNeeds = this.form.querySelector(
			`#adjustmentNeeds_${this.modalId}`
		);
		const accessNeeds = this.form.querySelector(`#accessNeeds_${this.modalId}`);

		let validationMessage = null;

		// Check criminal conviction fields
		if (
			criminalConviction &&
			criminalConviction.value === 'Yes' &&
			!convictionDetails.value
		) {
			convictionDetails.classList.add('field-error'); // Add error style
			validationMessage = 'Please fill in the criminal conviction details.';
		}

		// Check health-related fields
		if (
			healthIssues &&
			healthIssues.value === 'Yes' &&
			(!healthInfo.value || !adjustmentNeeds.value || !accessNeeds.value)
		) {
			// Highlight empty health-related fields
			if (!healthInfo.value) healthInfo.classList.add('field-error');
			if (!adjustmentNeeds.value) adjustmentNeeds.classList.add('field-error');
			if (!accessNeeds.value) accessNeeds.classList.add('field-error');

			validationMessage =
				'Please fill in all required details for health issues, adjustment needs, and access needs.';
		}

		return validationMessage; // Return error message if any validation fails
	}

	clearErrorStyles() {
		// Remove error styling from fields
		const errorFields = this.form.querySelectorAll('.field-error');
		errorFields.forEach((field) => field.classList.remove('field-error'));
	}

	showLoader() {
		if (this.loader) {
			this.loader.style.display = 'flex';
		}
	}

	hideLoader() {
		if (this.loader) {
			this.loader.style.display = 'none';
		}
	}

	closeModal() {
		const modalInstance = bootstrap.Modal.getInstance(this.modal);
		modalInstance.hide();
	}

	showSuccessModal(message, isSuccess = true) {
		this.successModalTitle.textContent = isSuccess ? 'Success' : 'Error';
		this.successModalBody.textContent = message;
		const successModalInstance = new bootstrap.Modal(this.successModal);
		successModalInstance.show();
	}
}

export default FormHandler;
