// class GdprHandler {
// 	constructor(formId, modalId) {
// 		this.form = document.getElementById(formId);
// 		this.modalId = modalId;

// 		if (!this.form) {
// 			return;
// 		}
// 		this.attachConditionalFieldListeners();
// 	}

// 	attachConditionalFieldListeners() {
// 		// Select the modal by data-modal-id to ensure unique scoping
// 		const modalElement = document.querySelector(
// 			`[data-modal-id="${this.modalId}"]`
// 		);

// 		if (!modalElement) {
// 			return;
// 		}

// 		// Find radio buttons and content sections by their unique modal-scoped IDs within this modal
// 		const criminalConvictionRadios = modalElement.querySelectorAll(
// 			`[name="criminalConviction_${this.modalId}"]`
// 		);
// 		const convictionDetails = modalElement.querySelector(
// 			`#convictionDetails_${this.modalId}`
// 		);
// 		const healthIssuesRadios = modalElement.querySelectorAll(
// 			`[name="healthIssues_${this.modalId}"]`
// 		);
// 		const healthDetails = modalElement.querySelector(
// 			`#healthDetails_${this.modalId}`
// 		);
// 		const adjustmentDetails = modalElement.querySelector(
// 			`#adjustmentDetails_${this.modalId}`
// 		);

// 		// Attach event listeners to criminal conviction radio buttons
// 		if (criminalConvictionRadios.length > 0 && convictionDetails) {
// 			criminalConvictionRadios.forEach((radio) => {
// 				radio.addEventListener('change', () => {
// 					convictionDetails.style.display =
// 						radio.value === 'Yes' ? 'block' : 'none';
// 				});
// 			});
// 		}

// 		// Attach event listeners to health issue radio buttons
// 		if (healthIssuesRadios.length > 0 && healthDetails && adjustmentDetails) {
// 			healthIssuesRadios.forEach((radio) => {
// 				radio.addEventListener('change', () => {
// 					const isYes = radio.value === 'Yes';
// 					healthDetails.style.display = isYes ? 'block' : 'none';
// 					adjustmentDetails.style.display = isYes ? 'block' : 'none';
// 				});
// 			});
// 		}
// 	}
// }

// export default GdprHandler;

class GdprHandler {
	constructor(formId, modalId) {
		this.form = document.getElementById(formId);
		this.modalId = modalId;

		if (!this.form) {
			return;
		}
		this.attachConditionalFieldListeners();
	}

	attachConditionalFieldListeners() {
		// Select the modal by data-modal-id to ensure unique scoping
		const modalElement = document.querySelector(
			`[data-modal-id="${this.modalId}"]`
		);

		if (!modalElement) {
			return;
		}

		// Find radio buttons and content sections by their unique modal-scoped IDs within this modal
		const criminalConvictionRadios = modalElement.querySelectorAll(
			`[name="criminalConviction_${this.modalId}"]`
		);
		const convictionDetails = modalElement.querySelector(
			`#convictionDetails_${this.modalId}`
		);
		const healthIssuesRadios = modalElement.querySelectorAll(
			`[name="healthIssues_${this.modalId}"]`
		);
		const healthDetails = modalElement.querySelector(
			`#healthDetails_${this.modalId}`
		);
		const adjustmentDetails = modalElement.querySelector(
			`#adjustmentDetails_${this.modalId}`
		);

		// Attach event listeners to criminal conviction radio buttons
		if (criminalConvictionRadios.length > 0 && convictionDetails) {
			criminalConvictionRadios.forEach((radio) => {
				radio.addEventListener('change', () => {
					const isYes = radio.value === 'Yes';
					convictionDetails.style.display = isYes ? 'block' : 'none';
					convictionDetails.querySelector('textarea').required = isYes;
				});
			});
		}

		// Attach event listeners to health issue radio buttons
		if (healthIssuesRadios.length > 0 && healthDetails && adjustmentDetails) {
			healthIssuesRadios.forEach((radio) => {
				radio.addEventListener('change', () => {
					const isYes = radio.value === 'Yes';
					healthDetails.style.display = isYes ? 'block' : 'none';
					adjustmentDetails.style.display = isYes ? 'block' : 'none';

					// Mark related fields as required when shown
					healthDetails.querySelector('textarea').required = isYes;
					adjustmentDetails.querySelector(
						`#adjustmentNeeds_${this.modalId}`
					).required = isYes;
					adjustmentDetails.querySelector(
						`#accessNeeds_${this.modalId}`
					).required = isYes;
				});
			});
		}
	}
}

export default GdprHandler;
